import {IRiskItem} from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {AnalyzerClass} from '../AnalyzerClass';
import {NitTools} from 'resources/classes/NursitTools';
import {CIRiskAssessment, QuestionnaireResponse} from "resources/classes/FhirModules/Fhir";
import {IQuestionnaireList, QuestionnaireService} from '../QuestionnaireService';
import {fhirEnums} from "../../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {AnalyzeService} from '../analyzeService';
import {DelphiTFormBuilder} from 'resources/classes/DelphiTFormBuilder';
import {TModelRiskNRS} from 'resources/classes/TModelRiskNRS';
import {ConfigService} from "../ConfigService";
import {PatientItem} from "../../classes/Patient/PatientItem";

export class AnalyzerV1 extends AnalyzerClass {
    ///// START IMPLEMENTATION/OVERLOADS HERE //////////////////

    _version = "V1";
    description: string = "This analyzer V1 - converted from delphi source";
    needToStoreFlags: boolean = true;

    public async calculateSpi(patient: PatientItem, from?: any): Promise<IRiskItem> {
        /** -= IMPORTANT  =-
         * we don't update the Questionnaire values in here,
         * because it is constantly called from assessment-view,
         * which handles the Updates */

        if (!patient.questionnaireResponses) patient.questionnaireResponses = [];

        let riskItem: IRiskItem = {
            name: "SPI",
            linkIdSum: "risk_spi_sum",
            linkIdIsRisk: "risk_spi",
            hasError: false,
            error: undefined,
            sum: undefined,
            textSum: '',
            textRisk: '',
            buttonText: this.i18n.tr("not_aviable"),
            title: this.i18n.tr("riskofpkms"),
            showButton: false,
            careLevel: undefined,
            versorgung: false
        };

        try {
            let fields = ['01_01', /* not included, even if the title says SPI...: '01_02', */ '05_01', '05_02', '05_03', '05_04', '03_01', '03_05', '04_01', '04_03', '06_03'];
            let assessment: any = from || patient.latestAssessment;

            if (!assessment) return riskItem;

            riskItem.sum = this.getCalcSum(assessment, fields, true);
            riskItem.isRisk = riskItem.sum <= 25; // AnalyzeService.PkmsRelevanceStart;

            riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
            riskItem.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);

            let str = this.i18n.tr("analyse_info_text");
            str = str.replace("%PATIENT%", patient.display)
                .replace("%CARELEVEL%", riskItem.textSum)
                .replace("%SPI%", riskItem.sum.toString());

            riskItem.textRisk = str;

            if (riskItem.isRisk) {
                riskItem.buttonText = this.i18n.tr("inform_social_services");
                riskItem.showButton = true;
            } else {
                riskItem.textRisk = this.i18n.tr("noobservablerisk");
            }

            riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
            riskItem.versorgung = riskItem.sum <= 32;
        } catch (e) {
            riskItem.hasError = true;
            riskItem.error = e.message;
        }

        patient.pkms_relevant = riskItem.sum <= AnalyzeService.PkmsRelevanceStart;
        patient.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);
        patient.careLevelString = Fhir.Tools.SpiToString(riskItem.sum);

        return riskItem;
    }

    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        // risk_deku_sum
        if (!assessment) assessment = patient.latestAssessment;
        let fields = ['01_01', '01_02', '01_03', '03_03', '04_08', '10_01'];
        let sum = this.getCalcSum(assessment, fields, false);

        if (sum < 17) {
            if (sum >= 13 && sum <= 16) {
                sum = 1;
            } else {
                sum = 2;
            }
        } else {
            sum = 0;
        }

        let text = this.i18n.tr("not_available");
        switch (sum) {
            default:
            case -1:
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
            case 2:
                text = this.i18n.tr("risk_high");
                break;
        }

        return {
            sum: sum,
            field: 'risk_deku',
            text: text
        };
    }

    public calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList) {
        try {
            if (!assessment) assessment = patient.latestAssessment;
            if (!assessment) {
                let msg = "No Assessment for Patient found";
                console.warn(msg);

                return undefined;
            }

            let lModel = new TModelRiskNRS(patient, qList);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_sum", true);
            // if (item) Fhir.QuestionnaireResponse.SetResponseItemValue(item, 0);
            item.answer = [{valueInteger: 0}];

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
            if (item) item.answer = [{valueInteger: 0}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, 0);

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_warning", true);
            if (item) item.answer = [{valueString: this.i18n.tr("not_available")}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, this.i18n.tr("not_available"));

            if (lModel.check()) {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_sum", true);
                if (item) item.answer = [{valueInteger: lModel.riskCheckOutcome}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.riskCheckOutcome);

                item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
                if (item) item.answer = [{valueInteger: lModel.riskCheckOutcome}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.riskOutCome);

                item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_warning", true);
                if (item) item.answer = [{valueString: lModel.resultMessage}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.resultMessage);
            } else {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
                if (item) item.answer = [{valueInteger: -1}];
            }

            return {
                linkIdSum: "risk_nrs_sum",
                linkIdIsRisk: "risk_nrs_calc",
                errorResult: lModel.riskOutCome,
                riskText: lModel.resultMessage,
                riskSum: lModel.riskCheckOutcome
            };
        } catch (error) {
            console.warn(error.message);
            return undefined;
        }
    }

    public calculateBarthelIndex(patient: PatientItem, assessment: any, biResponse: any): number {
        let formSum = 0;
        try {
            //#region do some checks first ...
            if (!biResponse) {
                let msg = "No BarthelIndex-Response given";
                console.warn(msg);
                return NaN;
            }

            let FBarthelIndexBuilder = new DelphiTFormBuilder(biResponse);
            if (!FBarthelIndexBuilder.Assessment) {
                let msg = "No Assessment for BarthelIndex existent!";
                console.error(msg);
                return NaN;
            }

            if (FBarthelIndexBuilder.Assessment.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
                let msg = "BarthelIndex is not in state in-progress. Returning stored value";
                console.debug(msg);
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexBuilder.Assessment, "BI_11");
                if (item) {
                    let bi11Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                    let int = parseInt(bi11Value);
                    return int;
                } else {
                    msg = "No previous stored value found for BI_11 in response. Aborting";
                    return NaN;
                }
            }

            // console.debug("Re-Calculating BarthelIndex");
            let FAssessmentBuilder = new DelphiTFormBuilder(assessment);
            //#endregion

            //#region ... then calculate the BI TODO: remove the Delphi FAssessmentBuilder class ...
            ///////////////////////////////////
            //Essen
            let Score = -1;
            if ((FAssessmentBuilder.GetKeyValue('03_01') == '03_01_04') &&
                ((FAssessmentBuilder.GetKeyValue('03_18') == '03_18_04') || (FAssessmentBuilder.GetKeyValue('03_18') == '03_18_01'))) {
                Score = 10;
            } else if ((FAssessmentBuilder.GetKeyValue('03_01') == '03_01_03') && (FAssessmentBuilder.GetKeyValue('03_18') == '03_18_04')) {
                Score = 5;
            } else if (
                (FAssessmentBuilder.GetKeyValue('03_18') == '03_18_01') &&
                (FAssessmentBuilder.GetKeyValue('03_01') == '03_01_03' || FAssessmentBuilder.GetKeyValue('03_01') == '03_01_02' || FAssessmentBuilder.GetKeyValue('03_01') == '03_01_01')
            ) {
                Score = 5;
            } else if (FAssessmentBuilder.GetKeyValue('03_18') == '03_18_04' && (FAssessmentBuilder.GetKeyValue('03_01') == '03_01_02' || FAssessmentBuilder.GetKeyValue('03_01') == '03_01_01')) {
                Score = 0;
            }

            FBarthelIndexBuilder.SetKeyValue('BI_01', Score);
            formSum += this.FormSum(Score);

            //Aufsetzen/ Umsetzen
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('01_02') == '01_02_04') Score = 15;
            else if (FAssessmentBuilder.GetKeyValue('01_02') == '01_02_03') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('01_02') == '01_02_02') Score = 5;
            else if (FAssessmentBuilder.GetKeyValue('01_02') == '01_02_01') Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_02', Score);
            formSum += this.FormSum(Score);

            //Sich waschen
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('05_01') == '05_01_04') Score = 5;
            else if (FAssessmentBuilder.GetKeyValueAsInteger('05_01') < 4) Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_03', Score);
            formSum += this.FormSum(Score);

            //Toilettenbenutzung
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('04_03') === '04_03_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('04_03') === '04_03_03' || FAssessmentBuilder.GetKeyValue('04_03') === '04_03_02') Score = 5;
            else if (FAssessmentBuilder.GetKeyValue('04_03') === '04_03_01') Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_04', Score);
            formSum += this.FormSum(Score);

            //Baden/Duschen
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('05_01') == '05_01_04'
                && FAssessmentBuilder.GetKeyValue('05_02') === '05_02_04'
                && FAssessmentBuilder.GetKeyValue('01_02') == '01_02_04') Score = 5;
            else Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_05', Score);
            formSum += this.FormSum(Score);

            //Aufstehen/ Gehen
            Score = -1;              //
            if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_04' && FAssessmentBuilder.GetKeyValue('01_02') === '01_02_04') Score = 15;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_04' && FAssessmentBuilder.GetKeyValue('01_02') === '01_02_03') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_03' && FAssessmentBuilder.GetKeyValue('01_02') === '01_02_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_03' && FAssessmentBuilder.GetKeyValue('01_02') === '01_02_03') Score = 5;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_01' || FAssessmentBuilder.GetKeyValue('01_02') === '01_02_02') Score = 0;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_02' || FAssessmentBuilder.GetKeyValue('01_02') === '01_02_01') Score = 0;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_01' || FAssessmentBuilder.GetKeyValue('01_02') === '01_02_01') Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_06', Score);
            formSum += this.FormSum(Score);

            //Treppensteigen
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_03') Score = 5;
            else if (FAssessmentBuilder.GetKeyValue('01_01') === '01_01_02' || FAssessmentBuilder.GetKeyValue('01_01') === '01_01_01') Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_07', Score);
            formSum += this.FormSum(Score);

            //An- und Auskleiden
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('05_03') === '05_03_04' && FAssessmentBuilder.GetKeyValue('05_04') === '05_04_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('05_03') === '05_03_04' && FAssessmentBuilder.GetKeyValueAsInteger('05_04') < 4) Score = 5;
            else if ((FAssessmentBuilder.GetKeyValueAsInteger('05_03') < 4 && FAssessmentBuilder.GetKeyValueAsInteger('05_04') < 4)
                || (FAssessmentBuilder.GetKeyValueAsInteger('05_03') === 3 && FAssessmentBuilder.GetKeyValueAsInteger('05_04') === 4)) Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_08', Score);
            formSum += this.FormSum(Score);

            //Stuhlkontinenz
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('04_04') === '04_04_04' && FAssessmentBuilder.GetKeyValue('04_10') === '04_10_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('04_03') === '04_03_04' && FAssessmentBuilder.GetKeyValue('04_10') === '04_10_01') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('04_04') === '04_04_03' && FAssessmentBuilder.GetKeyValue('04_10') === '04_10_04') Score = 5;
            else if (FAssessmentBuilder.GetKeyValueAsInteger('04_03') < 4
                && FAssessmentBuilder.GetKeyValueAsInteger('04_03') > 0
                && FAssessmentBuilder.GetKeyValue('04_10') === '04_10_01') Score = 5;
            else if (FAssessmentBuilder.GetKeyValueAsInteger('04_04') < 3
                && FAssessmentBuilder.GetKeyValueAsInteger('04_04') > 0
                && FAssessmentBuilder.GetKeyValue('04_10') === '04_10_04') Score = 0;
            FBarthelIndexBuilder.SetKeyValue('BI_09', Score);
            formSum += this.FormSum(Score);

            //Harninkontinenz
            Score = -1;
            if (FAssessmentBuilder.GetKeyValue('04_01') === '04_01_04'
                && FAssessmentBuilder.GetKeyValue('04_02') === '04_02_04'
                && FAssessmentBuilder.GetKeyValue('04_09') === '04_09_04') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('04_01') === '04_01_04'
                && (FAssessmentBuilder.GetKeyValue('04_02') === '04_02_04' || FAssessmentBuilder.GetKeyValue('04_02') === '04_02_01')
                && FAssessmentBuilder.GetKeyValue('04_09') === '04_09_01') Score = 10;
            else if (FAssessmentBuilder.GetKeyValue('04_01') === '04_01_04'
                && FAssessmentBuilder.GetKeyValue('04_02') === '04_02_03'
                && FAssessmentBuilder.GetKeyValue('04_09') === '04_09_04') Score = 5;
            else if (FAssessmentBuilder.GetKeyValueAsInteger('04_01') < 4
                && FAssessmentBuilder.GetKeyValueAsInteger('04_01') > 0
                && (FAssessmentBuilder.GetKeyValue('04_02') === '04_02_04' || FAssessmentBuilder.GetKeyValue('04_02') === '04_02_01')
                && FAssessmentBuilder.GetKeyValue('04_09') === '04_09_01') Score = 5;
            else if (FAssessmentBuilder.GetKeyValueAsInteger('04_02') < 3
                && FAssessmentBuilder.GetKeyValueAsInteger('04_02') > 0
                && FAssessmentBuilder.GetKeyValue('04_09') === '04_09_04') Score = 0; // VERMUTUNG!
            FBarthelIndexBuilder.SetKeyValue('BI_10', Score);
            formSum += this.FormSum(Score);
            //#endregion

            /* let fields = ["BI_10", "BI_09", "BI_08", "BI_07", "BI_06", "BI_05", "BI_04", "BI_03", "BI_02", "BI_01"];
            formSum = 0;
            fields.forEach(linkId => {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexBuilder.Assessment, linkId, false);
                if (item) {
                    let val = Fhir.QuestionnaireResponse.GetItemAnswer(item.answer[0], "0");
                    formSum += Fhir.QuestionnaireResponse.GetKeyValueAsInteger(val);
                }
            }); */

            // Fhir.QuestionnaireResponse.SetResponseItemValue(Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexBuilder.Assessment, "BI_11", true), formSum);
            let bi11Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexBuilder.Assessment, "BI_11", true);
            if (bi11Item) bi11Item.answer = [{valueInteger: formSum}];


            // console.debug("SUM " + formSum);

            return formSum;
        } catch (e) {
            console.warn("Could not calculate BI because of error: " + e.message);
            return NaN;
        }
    }

    public calculateBartheldIndexEx(patient: PatientItem, assessment: any, anamnesis: any, biExResponse: any): number {
        // let FBarthelIndexExBuilder = new DelphiTFormBuilder(response);
        //#region do some checking ....
        if (!assessment) {
            let msg = "No Assessment. Aborting";
            console.warn(msg);
            return NaN;
        }

        if (!biExResponse) {
            let msg = "No BI-Ex Document. Aborting";
            console.warn(msg);
            return NaN;
        }

        // ensure a structural correct response with all answers
        let biExquestionnaire = QuestionnaireService.GetQuestionnaireDirect(QuestionnaireService.__listResult.QBarthelIndexExId);
        Fhir.Questionnaire.SetDefaultValues(biExquestionnaire, biExResponse, undefined);

        if (biExResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
            let msg = "BiEx-Status is not in-progress. Returning stored value..";
            console.warn(msg);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, "EBI_07");
            if (item) {
                let ebi07Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                let int = parseInt(ebi07Value);
                return int;
            } else {
                return NaN;
            }
        }
        //#endregion

        //#region ... then perform the calulcation ...
        //let FAnamneseBuild = new DelphiTFormBuilder(anamnesis);
        //let FAssessmentBuilder = new DelphiTFormBuilder(assessment);

        let formSum = 0; // summed up value stored to EBI_07 at the end
        let value0602Int = QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '06_02'); // proxy value for Assessment.06_02
        let value0603Int = QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '06_03'); // proxy value for Assessment.06_03

        //#region ... for "Verstehen" (EBI_01) ...
        let value0603 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '06_03');
        let mappings0603 = {
            '06_03_04': 15,
            '06_03_03': 10,
            '06_03_02': 5,
            '06_03_01': 0
        };

        let scoreEbi01 = mappings0603[value0603] || 0;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_01', `EBI_01_${this.makeTwo(scoreEbi01)}`);

        /* if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_04') Score = 15;
        else if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_03') Score = 10;
        else if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_02') Score = 5;
        else if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_01') Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_01', Score); */
        formSum += scoreEbi01;
        //#endregion

        //#region ... for "Sich verständlich machen" (EBI_02) ...
        let mapping0709 = {
            '07_09_04': 15,
            '07_09_02': 5,
            '07_09_01': 0
        };
        let value0709 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '07_09', false);
        let scoreEbi02 = mapping0709[value0709] || 0;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_02', `EBI_02_${this.makeTwo(scoreEbi02)}`);
        /* if (FAssessmentBuilder.GetKeyValue('07_09') === '07_09_04') Score = 15;
        else if (FAssessmentBuilder.GetKeyValue('07_09') === '07_09_02') Score = 5;
        else if (FAssessmentBuilder.GetKeyValue('07_09') === '07_09_01') Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_02', Score); */
        formSum += scoreEbi02;
        //#endregion

        //#region ... for "Soziale Interaktionen" (EBI_03) ...
        let scoreEbi03 = 0;
        let value0706 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '07_06');
        if (value0706 === '07_06_04') scoreEbi03 = 15;
        else if (value0602Int > 2 && value0602Int < 5) scoreEbi03 = 5;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_03', `EBI_03_${this.makeTwo(scoreEbi03)}`);

        /* if (FAssessmentBuilder.GetKeyValueAsInteger('07_06') === 4) Score = 15;
        else if (FAssessmentBuilder.GetKeyValueAsInteger('07_06') != 4 && FAssessmentBuilder.GetKeyValueAsInteger('06_02') > 2 && FAssessmentBuilder.GetKeyValueAsInteger('06_02') < 5) Score = 5;
        else if (FAssessmentBuilder.GetKeyValueAsInteger('07_06') != 4  && FAssessmentBuilder.GetKeyValueAsInteger('06_02') > 0 && FAssessmentBuilder.GetKeyValueAsInteger('06_02') < 3) Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_03', Score); */
        formSum += scoreEbi03;
        //#endregion

        //#region ... for "Lösen von Alltagsproblemen" (EBI_04) ...
        let mapping0605 = {
            '06_05_04': 15,
            '06_05_03': 5,
            '06_05_02': 0,
            '06_05_01': 0
        };

        let value0605 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '06_05');
        let scoreEbi04 = mapping0605[value0605] || 0;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_04', `EBI_04_${this.makeTwo(scoreEbi04)}`);
        /*
        if (FAssessmentBuilder.GetKeyValue('06_05') === '06_05_04') Score = 15;
        else if (FAssessmentBuilder.GetKeyValue('06_05') === '06_05_03') Score = 5;
        else if (FAssessmentBuilder.GetKeyValue('06_05') === '06_05_02' || FAssessmentBuilder.GetKeyValue('06_05') === '06_05_01') Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_04', Score); */
        formSum += scoreEbi04;
        //#endregion

        //#region ... for "Gedächtnis, Lernen und Orientierung" (EBI_05) ...
        let scoreEbi05 = 0;
        if (value0603Int === 4 && value0602Int === 4) scoreEbi05 = 15;
        else if (value0603Int === 4 && value0602Int === 3) scoreEbi05 = 10;
        else if (value0603Int === 3 && value0602Int === 4) scoreEbi05 = 10;
        else if (value0603Int != 4 && value0602Int > 1 && value0602Int < 4) scoreEbi05 = 5;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_05', `EBI_05_${this.makeTwo(scoreEbi05)}`);
        /* if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_04' && FAssessmentBuilder.GetKeyValue('06_02') === '06_02_04') Score = 15;
        else if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_04' && FAssessmentBuilder.GetKeyValue('06_02') === '06_02_03') Score = 10;
        else if (FAssessmentBuilder.GetKeyValue('06_03') === '06_03_03' && FAssessmentBuilder.GetKeyValue('06_02') === '06_02_04') Score = 10;
        else if (FAssessmentBuilder.GetKeyValueAsInteger('06_03') != 4 && FAssessmentBuilder.GetKeyValueAsInteger('06_02') > 1 && FAssessmentBuilder.GetKeyValueAsInteger('06_02') < 4) Score = 5;
        else if (FAssessmentBuilder.GetKeyValue('06_02') === '06_02_01') Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_05', Score); */
        formSum += scoreEbi05;
        //#endregion

        //#region ... for "Sehen und Neglect" (EBI_06) ...
        let scoreEbi06 = 0;
        let value0708Int = QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '07_08');
        let value9930Int = QuestionnaireResponse.GetResponseItemValueIntByLinkId(anamnesis, '99_30');
        let value9931Int = QuestionnaireResponse.GetResponseItemValueIntByLinkId(anamnesis, '99_31');

        if (value0708Int === 4 && (value9931Int === 1 || value9930Int === 1)) scoreEbi06 = 15;
        else if (value0708Int === 4 && ((value9931Int === 2 || value9931Int === 3) || (value9930Int === 2 || value9930Int === 3))) scoreEbi06 = 10;
        else if (value0708Int === 2) scoreEbi06 = 5;
        QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_06', `EBI_06_${this.makeTwo(scoreEbi06)}`);

        /* if (FAssessmentBuilder.GetKeyValue('07_08') === '07_08_04'
            && (FAnamneseBuild.GetKeyValueAsInteger('99_31') === 1 || FAnamneseBuild.GetKeyValueAsInteger('99_30') === 1)) Score = 15;
        else if (FAssessmentBuilder.GetKeyValue('07_08') === '07_08_04' &&
            (
                ([2, 3].indexOf(FAnamneseBuild.GetKeyValueAsInteger('99_31')) > -1) || ([2, 3].indexOf(FAnamneseBuild.GetKeyValueAsInteger('99_30')) > -1)
            )
        ) Score = 10;
        else if (FAssessmentBuilder.GetKeyValue('07_08') === '07_08_02') Score = 5;
        else if (FAssessmentBuilder.GetKeyValue('07_08') === '07_08_01') Score = 0;
        FBarthelIndexExBuilder.SetKeyValue('EBI_06', Score); */

        formSum += scoreEbi06;
        //#endregion

        //#endregion

        //#region ... and store the result from formSum into "Summe" (EBI_07)
        /*let ebi7Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true);
        if (ebi7Item) ebi7Item.answer = [{ valueInteger: formSum }]; // Fhir.QuestionnaireResponse.SetResponseItemValue(Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true), formSum); */
        QuestionnaireResponse.SetResponseItemIntByLinkId(biExResponse, 'EBI_07', formSum);
        //#endregion

        return formSum;
    }

    public calculateFall(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let fields = [
            '01_01', '01_06', '01_07',
            '01_05', '01_08', '04_05',
            '06_02', '06_07', '07_08'
        ];

        let sum: number = -1;

        for (let i = 0; i < fields.length; i++) {
            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, fields[i]);
            let value = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
            if (!isNaN(value) && (value === 1 || value === 2)) {
                sum = 1;
                break;
            }
        }

        let textValue = this.i18n.tr("n_a");
        let isRisk: boolean = false;

        if (sum === 0) {
            textValue = this.i18n.tr("risk_unlikely");
        } else if (sum > 0) {
            textValue = this.i18n.tr("increased_risk");
            isRisk = true;
        }

        return {
            text: textValue,
            isRisk: isRisk,
            field: 'risk_sturz'
        };
    }

    public calculatePneumo(patient: PatientItem, assessment?: any) {
        let pneuFields = [
            '01_01', '03_10', '06_01',
            '09_01', '09_02', '09_03',
            '09_04', '09_06',
        ];

        if (!assessment) assessment = patient.latestAssessment;
        let sum = -1;

        for (let i = 0; i < pneuFields.length; i++) {
            let field = pneuFields[i];
            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, field);
            let val: string = String(Fhir.QuestionnaireResponse.GetResponseItemValue(item));
            let intVal = Fhir.QuestionnaireResponse.GetKeyValueAsInteger(val);
            if (intVal === 1 || intVal === 2) {
                sum = 1;
                break;
            }
        }

        let text = this.i18n.tr("n_a");
        switch (sum) {
            default:
                break;
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
        }

        return {
            text: text,
            field: 'risk_pneu',
            sum: sum,
            isRisk: sum >= 1
        };
    }

    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) return undefined;

        let v04_01Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_01');
        let v04_01: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_01Item);

        let v04_02Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_02');
        let v04_02: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_02Item);

        let v04_09Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_09');
        let v04_09: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_09Item);

        let v07_06Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '07_06');
        let v07_06: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v07_06Item);

        let contincenceValue = -1;
        let inkontinenzText = "";
        if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 4)) {
            inkontinenzText = this.i18n.tr("kontinenz");
            contincenceValue = 0;
        } else if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 1)) {
            inkontinenzText = this.i18n.tr("unabhaengigerreichtekontinenz");
            contincenceValue = 1;
        } else if ((v04_01 != 4) && (v04_02 == 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("abhaengigerreichtekontinenz");
            contincenceValue = 2;
        } else if ((v04_01 == 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("unabhaengigkompensierteinkontinenz");
            contincenceValue = 3;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && ([4, 11, 13].indexOf(v07_06) > -1)) {
            inkontinenzText = this.i18n.tr("abhaengigkompensierteinkontinenz");
            contincenceValue = 4;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && (v07_06 == 12 || v07_06 == 14)) {
            contincenceValue = 5;
            inkontinenzText = this.i18n.tr("nichtkompensierteinkontinenz");
        }

        return {
            field: 'kontinenz',
            text: inkontinenzText,
            value: contincenceValue
        };
    }

    public calculateVdd(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;

        let vddFields = [
            '06_02', '06_03', '06_05', '06_06',
            '06_07', '07_10', '07_06', '08_02'
        ];

        let sum = -1;

        for (let i = 0; i < vddFields.length; i++) {
            let field = vddFields[i];
            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, field);
            let val = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
            if (field === '07_06') {
                if ([11, 12, 13, 14].indexOf(val) > -1) {
                    sum += 1;
                }
            } else {
                if (val === 1 || val === 2) {
                    sum += 1;
                }
            }
        }

        if (sum > 1) sum = 1;
        else sum = 0;

        let textValue = "?";
        switch (sum) {
            case 0:
                textValue = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                textValue = this.i18n.tr("risk_high");
                break;
        }

        return {
            text: textValue,
            isRisk: sum > 0,
            field: 'risk_vdd'
        };
    }

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);
        let qList = await QuestionnaireService.GetQuestionnaireIds();

        let aConfig = ConfigService.GetFormSettings('assessment');
        if (aConfig) {
            let aQ = QuestionnaireService.GetQuestionnaireByNameDirect(aConfig.questionnaireName);
            if (aQ) {
                if (!assessment || (assessment && assessment.questionnaire && assessment.questionnaire.reference.indexOf('/' + aQ.id) === -1) || this.assessmentName !== aQ.name) {
                    if (aConfig && aQ) {
                        assessment = QuestionnaireService.GetLatestResponseOfType(patient, aQ.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                        if (assessment) {
                            QuestionnaireService.__listResult.QAssessmentId = aQ.id;
                            qList.QAssessmentId = aQ.id;
                            this.assessmentName = aQ.name;
                        }
                    }
                }
            }
        }

        if (ConfigService.Debug) 
            console.debug('This is Analyzer Version: ' + this._version + ' using AssessmentName: ' + this.assessmentName);

        if (!assessment) {
            if (ConfigService.Debug) console.warn("No Assessment in ", patient);
            return;
        }

        let raBackup = NitTools.Clone(patient.currentRisks);
        // check for the riskAssessment
        if (updateRiskAssessment && !patient.currentRisks) {
            let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, anamnesis);
        }

        let resultPKMS = await this.calculateSpi(patient, assessment);
        let resultDecu = this.calculateRiskDecu(patient, assessment);
        let resultPneumo = this.calculatePneumo(patient, assessment);
        let resultFall = this.calculateFall(patient, assessment);
        let resultVDD = this.calculateVdd(patient, assessment);
        let resultKontinenz = this.calculateIncontinenceProfile(patient, assessment);
        let resultNRS = this.calculateNRS(patient, assessment, qList);

        let bi = this.calculateBarthelIndex(patient, assessment, await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindex', "BarthelIndex"));
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        let biEx = this.calculateBartheldIndexEx(patient, assessment, anamnesis, await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindexEx', "BarthelIndexEx"));
        let icdItems = await this.icdService.getICDCodes(patient);
        let showMissingFieldsWarning = false;
        icdItems = icdItems.filter(o => o.test());
        /*        if (ConfigService.Debug) {
                    console.info("TODO:");
                    console.info("[Fhir.Analyse] - ICDS:", icdItems);
                } */

        //#region set pkms value
        // PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment);

        await PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment, patient.careLevelText, patient.careLevelColor, resultPKMS.sum);

        let itemPkmsText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_sum_text', true);
        if (itemPkmsText) itemPkmsText.answer = [{valueString: resultPKMS.textSum}];
        else {
            console.warn("No 'risk_spi_sum_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsRText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_risk_text', true);
        if (itemPkmsRText) itemPkmsRText.answer = [{valueString: resultPKMS.textRisk}];
        else {
            console.warn("No 'risk_spi_risk_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdSum, true);
        if (itemPkmsSum) {
            itemPkmsSum.answer = [
                {valueInteger: resultPKMS.sum}
            ];
            itemPkmsSum.linkId = resultPKMS.linkIdSum;
        } else {
            console.warn(`No '${resultPKMS.linkIdSum}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPkmsRisk = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdIsRisk, true);
        if (itemPkmsRisk) {
            itemPkmsRisk.answer = [
                {valueBoolean: resultPKMS.isRisk}
            ];
            itemPkmsRisk.linkId = resultPKMS.linkIdIsRisk;
        } else {
            console.warn(`No '${resultPKMS.linkIdIsRisk}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        //#endregion

        //#region Decubitus
        // WO HIN SPEICHERN?
        let itemDecu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field, true);
        if (itemDecu) {
            itemDecu.answer = [
                {valueBoolean: resultDecu.sum >= 1}
            ];
        } else {
            console.warn(`No '${resultDecu.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemDecuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_sum", true);
        if (itemDecuSum) {
            itemDecuSum.answer = [
                {valueInteger: resultDecu.sum}
            ];
        } else {
            console.warn(`No '${resultDecu.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let decuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_text", true);
        if (decuText) {
            decuText.answer = [{valueString: resultDecu.text}];
        } else {
            console.warn(`No '${resultDecu.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region pneumo
        let itemPneu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field, true);
        if (itemPneu) {
            itemPneu.answer = [
                {valueBoolean: resultPneumo.isRisk}
            ];
            itemPneu.linkId = resultPneumo.field;
        } else {
            console.warn(`No '${resultPneumo.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + '_sum', true);
        if (itemPneuSum) {
            itemPneuSum.answer = [
                {valueInteger: resultPneumo.sum}
            ];
        } else {
            console.warn(`No '${resultPneumo.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + "_text", true);
        if (itemPneuText) {
            itemPneuText.answer = [{valueString: resultPneumo.text}];
            itemPneuSum.linkId = resultPneumo.field + '_sum';
        } else {
            console.warn(`No '${resultPneumo.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region fall
        let itemFall = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field, true);
        if (itemFall) {
            itemFall.answer = [
                {
                    valueBoolean: resultFall.isRisk
                }
            ];
            itemFall.linkId = resultFall.field;
        } else {
            console.warn(`No '${resultFall.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemFallText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field + "_text", true);
        if (itemFallText) {
            itemFallText.answer = [{
                valueString: resultFall.text
            }];
        } else {
            console.warn(`No '${resultFall.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region vdd
        let itemVdd = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field, true);
        if (itemVdd) {
            itemVdd.answer = [
                {
                    valueBoolean: resultVDD.isRisk
                }
            ];
            itemVdd.linkId = resultVDD.field;
        } else {
            console.warn(`No '${resultVDD.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemVddText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field + "_text", true);
        if (itemVddText) {
            itemVddText.answer = [{valueString: resultVDD.text}];
        } else {
            console.warn(`No '${resultVDD.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region continence
        let itemKonti = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field, true);
        if (itemKonti) {
            itemKonti.answer = [{valueInteger: resultKontinenz.value}];
        } else {
            console.warn(`No '${resultKontinenz.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemKontiText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field + "_text", true);
        if (itemKontiText) {
            itemKontiText.answer = [{valueString: resultKontinenz.text}];
        } else {
            console.warn(`No '${resultKontinenz.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        // TODO: DOUBLE CHECK the assigned values an especially the storage of the NRS items
        //#region NRS
        if (resultNRS) {
            let nrsSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdSum, true);
            if (nrsSumItem) {
                nrsSumItem.answer = [
                    {
                        valueInteger: resultNRS.riskSum
                    }
                ];
                nrsSumItem.linkId = resultNRS.linkIdSum;
            } else {
                console.warn(`No '${resultNRS.linkIdSum}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdIsRisk, true);
            if (nrsRiskItem) {
                nrsRiskItem.answer = [
                    {valueInteger: resultNRS.errorResult}
                ];
                nrsRiskItem.linkId = resultNRS.linkIdIsRisk;
            } else {
                console.warn(`No '${resultNRS.linkIdIsRisk}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItemText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_nrs_warning', true);
            if (nrsRiskItemText) {
                nrsRiskItemText.answer = [{valueString: resultNRS.riskText}];
            } else {
                console.warn(`No 'risk_nrs_warning' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }
        } else {
            console.warn('No NRS-Result generated');
        }
        //#endregion

        //#region BI
        let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BI_11', true);
        if (biItem) {
            biItem.answer = [
                {valueInteger: bi}
            ];
        } else {
            console.warn(`No 'BI_11' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region BI-EX
        let biExItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'EBI_07', true);
        if (biExItem) {
            biExItem.answer = [
                {valueInteger: biEx}
            ];
        } else {
            console.warn(`No 'EBI_07' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region if this is the latestAssessment update everything and even riskassessment
        if (patient.latestAssessment && patient.latestAssessment.id === assessment.id) {
            let spiSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdSum, true);
            if (spiSumItem) spiSumItem.answer = [{valueInteger: resultPKMS.sum}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(spiSumItem, resultPKMS.sum, resultPKMS.textSum);

            let spiRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdIsRisk, true);
            Fhir.QuestionnaireResponse.SetResponseItemCoding(spiRiskItem, String(resultPKMS.isRisk), resultPKMS.textRisk);
            await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

            //#region update RiskAssessment
            if (updateRiskAssessment) {
                CIRiskAssessment.ApplyRisksFromAssessment(patient.currentRisks, assessment);

                this.updateRiskAssessmentPrediction(resultPKMS, patient)
                    .updateRiskAssessmentPrediction(
                        {
                            linkIdSum: resultDecu.field,
                            sum: resultDecu.sum,
                            textSum: resultDecu.text
                        }, patient)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultPneumo, patient, true)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultFall, patient, false)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultVDD, patient, false);
                if (resultKontinenz) {
                    this.updateRiskAssessmentPrediction({
                        linkIdSum: resultKontinenz.field,
                        sum: resultKontinenz.value,
                        textSum: String(resultKontinenz.text)
                    }, patient);
                }

                if (resultNRS) {
                    this.updateRiskAssessmentPrediction(
                        {
                            linkIdIsRisk: resultNRS.linkIdIsRisk,
                            isRisk: resultNRS.riskSum > 0,
                            riskText: resultNRS.riskText,
                            linkIdSum: resultNRS.linkIdSum,
                            sum: resultNRS.errorResult,
                            textSum: resultNRS.riskText
                        }, patient);
                }

                this.updateRiskAssessmentPrediction({
                    linkIdSum: "BarthelIndex",
                    sum: String(bi),
                    textSum: String(bi)
                }, patient)
                    .updateRiskAssessmentPrediction({
                        linkIdSum: "BarthelIndexEx",
                        sum: String(biEx),
                        textSum: String(biEx)
                    }, patient);

                if (showMissingFieldsWarning) {
                    let msg = "There are missing fields in the Assessment.<br />Please read the Console Warnings and update the Questionnaire.";
                    // RuntimeInfo.ShowInfo(msg);
                    console.warn(msg);
                }

                if (anamnesis && assessment) {
                    if ((["finished", "ameneded"].indexOf(anamnesis.status) > -1) && (["finished", "ameneded"].indexOf(assessment.status) > -1)) {
                        patient.currentRisks.status = "final";
                    }
                }

                await this.updateRiskAssessmentValues(patient, assessment, patient.currentRisks);
                if (!CIRiskAssessment.AreEqual(patient.currentRisks, raBackup) && storeRiskAssessment) {
                    if (ConfigService.Debug) console.debug("UPDATE RISKASSESSMENT\n------WRITING RA ---------------", patient.currentRisks);

                    patient.currentRisks = <any>await this.fhirService.update(patient.currentRisks);
                }
            }
            //#endregion
        }
        //#endregion

        await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

        this.patientChangeNotifier.notify(patient);

        return {
            pkmsIsRisk: resultPKMS.isRisk,
            pkmsSum: resultPKMS.sum
        };
    }
}
